
.header-band{
  background: url('../images/header_band.png') left center;
  padding: 20px 0 20px;
  margin-bottom: 30px;

  position:relative;
  h1,h2,h3,h4{
    text-align:center;
  }

  .header-band-image{
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-size: cover;
    background-position: center center;
    //opacity: .2;
  }
}