.news-box-item{
  margin-bottom: 25px;
  a{
    text-align:left;


  }

  h1,h2,h3{
    a {
      color: #000;
    }
  }
  p{
    font-size: 14px;
    line-height: 140%;
  }
}

.article-image{
  margin-bottom: 20px;
}

.article-content{
  font-size:15px;
}

.article-date{
  font-size: 16px;
  color: #666;
  display:block;
  margin-bottom: 10px;
}

.article-wrapper{
  margin-bottom: 30px;

  .addthis_inline_share_toolbox{
    margin-top:30px;
  }
}

.news-box{
  h3, p{
    text-align:left;
    margin-bottom: 0px;
  }

  a{
    img{
      text-align:center;
      margin: 0 auto;
    }
  }
}