.divider-header-property{
  padding: 10px 0 !important;
}

.property-wrapper{
  //padding:30px 0;
  margin-top: 30px;

  .container{
    //background: #fff;
    //padding: 30px;
  }

  .property-content{
   // background: #fff;
   // padding: 30px;
  }



  h2.page-header{
    margin: 25px 0 15px;
  }

  h2{
    color:#3a3f4c;
    font-size: 18px;
    &:after{
      display: none;
    }
    .first-word{
      color:#3a3f4c;
    }
  }
}

.property-aside-content{
  background: #fff;
 // padding: 30px;
}

.property-content-gallery{
  margin: -30px -30px 0 -30px;
}


.property-main-gallery{
  .property-main-gallery-item{
    text-align:center;
    img{
      max-width: 100%;
      height: auto;
      max-height: 540px;
      display: block;
      vertical-align: middle;
      text-align: center;
      margin:0 auto;
    }
  }
}

.property-show-price{
  font-weight: bold;
  //margin-top:30px;
  display: block;
  text-align: right;
  font-size:24px;
  color: @brand-color;
}

.property-show-short-caracteristics{
  margin-top: 30px;
  text-align: center;
  float:left;
  margin-right: 20px;

  .box-short{
    i,i:before{
      font-size:40px;
      // display:block;
    }

    .box-short-title{
      font-weight: bold;
      display: block;
    }
  }
}

.property-show-lista-tabelara{
  margin:0;
  padding:0;
  li{
    padding:0;
    list-style:none;
    border-bottom: 1px solid #f7f7f7;

    span{
      float: right;
      font-weight: bold;
    }
  }
}

.property-show-specs ul{
  list-style:none;
  .fa{
    opacity:.5;
  }
  li span{
    font-weight: bold;
  }
}

#window-map{
  height: 350px;
}

.property-show-contact-short{
  //  background:#1565C0;
  // padding: 15px;
  .agent-small-content{
    border-bottom: none;
  }
}

.agent-contact-phone{
  clear:both;
  background:@brand-color;
  padding: 15px;
  color: #fff;
  font-size: 18px;
  display: block;
  text-align: center;
  margin-top: 15px;
}

.contact-form{
  margin-top:30px;

  textarea{
    min-height: 120px;
  }
  .btn{
    width: 100%;
    color: #fff;
    background:@brand-color;
  }
}


.property-show-contact-vertical{
  border: 1px solid #EEEEEE;
  padding: 30px;
  // background: #FAFAFA;
}

.property-show-title{
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.property-show-zone{
  margin-top:10px;
  font-size: 18px;
  color: #515666;
}

