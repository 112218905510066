.clearfix:after{

  content: "";
  display: table;
  clear: both;
}
.background-image-cover{
  background-size: cover;
  background-position: center center;
  //opacity: .2;
}

.background-image-centered{
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.outsize-image{
  margin:-50px 0 -50px 0;
}

@media(max-width: @screen-md) {
  .outsize-image{
    margin:auto;
  }
}

.display-block{
  display:block;
}
.pt50{
  padding-top: 50px !important;
}

.pb50{
  padding-bottom: 50px !important;
}

.ptb50{
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.mb30 {
  margin-bottom: 30px !important; }

.ml30 {
  margin-left: 30px !important; }

.mt0 {
  margin-top: 0px !important; }
.mt10 {
  margin-top: 10px !important; }
.mt16 {
  margin-top: 16px !important; }

.mt30 {
  margin-top: 30px !important; }

.mt-30 {
  margin-top: -30px !important; }

.mt-60 {
  margin-top: -60px !important; }

.mt60{
  margin-top: 60px !important; }

.mb0 {
  margin-bottom: 0px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.mb-30 {
  margin-bottom: -30px !important; }

.mb60 {
  margin-bottom: 60px !important; }

.mb-60 {
  margin-bottom: -60px !important; }

.no-margin {
  margin: 0px !important; }

.strike {
  text-decoration: line-through; }

.min-width {
  min-width: 1px; }

.medium {
  font-weight: 500; }

.semi-bold {
  font-weight: 600; }

.nowrap {
  white-space: nowrap; }

.blur {
  -webkit-filter: blur(3px);
  bottom: 0px;
  left: 0px;
  position: absolute;
  right: 0px;
  top: 0px; }

.relative {
  position: relative; }

.overflow-hidden {
  overflow: hidden; }

h1.page-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.7);
  font-size: 28px;
  line-height: 60px;
  margin: 0px 0px 30px 0px;
  padding: 0px; }

h2.page-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.7);
  font-size: 24px;
  line-height: 48px;
//  margin: 0px 0px 30px 0px;
  padding: 0px; }

h3.page-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.7);
  font-size: 18px;
  line-height: 40px;
  margin: 0px 0px 15px 0px;
  padding: 0px; }

.small-title {
  color: #9E9E9E;
  display: block;
  font-size: 12px;
  font-weight: 600;
  margin: 0px 0px 5px 0px;
  text-transform: uppercase; }

.large-title {
  text-align: center; }
.large-title h1 {
  color: #EC407A;
  font-size: 200px; }
.large-title h2 {
  color: #9E9E9E;
  font-size: 36px; }

p.text {
  color: #616161; }

.block {
  margin: 0px 0px 30px 0px;
  padding: 60px 0px; }
.block h1 {
  color: #616161;
  font-size: 30px;
  margin: 0px 0px 50px 0px;
  padding: 0px; }
.block p {
  color: #616161;
  font-size: 14px;
  margin: 0px; }
.block p a {
  border-bottom: 1px solid #EC407A;
  color: #EC407A; }
.block p a:hover {
  text-decoration: none; }

.box {
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  margin: 0px 0px 30px 0px;
  padding: 16px; }
.box h1 {
  margin: 10px 0px 26px 0px;
  font-size: 22px; }

.fullwidth {
  margin-left: -750px;
  margin-right: -750px;
  padding-left: 750px;
  padding-right: 750px; }

.background-grey {
  background-color: #F5F5F5; }

.background-map {
  background-image: url("../img/map.png");
  background-position: center center;
  background-repeat: repeat; }

.background-white {
  background-color: #fff; }

.center {
  text-align: center; }

.vertical-align {
  vertical-align: middle; }

.min-width {
  width: 1px; }

.alert {
  border-radius: 0px;
  border: 0px;
  border-left: 3px solid transparent; }

.alert-info {
  background-color: #E1F5FE;
  border-left-color: #81D4FA;
  color: #0277BD; }

.alert-success {
  background-color: #E8F5E9;
  border-left-color: #A5D6A7;
  color: #2E7D32; }

.alert-danger {
  background-color: #FFEBEE;
  border-left-color: #EF9A9A;
  color: #C62828; }

.alert-warning {
  background-color: #FFF8E1;
  border-left-color: #FFE082;
  color: #FF6F00; }

.circle-color {
  border-radius: 50%;
  display: inline-block;
  height: 36px;
  margin: 0px 15px 15px 0px;
  width: 36px; }

.circle-color-red {
  background-color: #EF5350; }

.circle-color-pink {
  background-color: #EC407A; }

.circle-color-deep-purple {
  background-color: #7E57C2; }

.circle-color-indigo {
  background-color: #5C6BC0; }

.circle-color-blue {
  background-color: #42A5F5; }

.circle-color-light-blue {
  background-color: #29B6F6; }

.circle-color-cyan {
  background-color: #26C6DA; }

.circle-color-teal {
  background-color: #26A69A; }

.circle-color-green {
  background-color: #66BB6A; }

.circle-color-light-green {
  background-color: #9CCC65; }

.circle-color-lime {
  background-color: #D4E157; }

.circle-color-yellow {
  background-color: #FFEE58; }

.circle-color-amber {
  background-color: #FFCA28; }

.circle-color-orange {
  background-color: #FFA726; }

.circle-color-deep-orange {
  background-color: #FF7043; }

.circle-color-brown {
  background-color: #8D6E63; }

.circle-color-blue-grey {
  background-color: #78909C; }

.sharing-buttons {
  margin-top: 20px;
  padding-left: 0px; }
.sharing-buttons li {
  display: inline-block;
  margin-bottom: 16px; }
.sharing-buttons li a {
  background-color: #EC407A;
  color: #fff;
  font-size: 13px;
  padding: 8px 15px;
  position: relative;
  text-decoration: none; }
.sharing-buttons li a:before {
  content: "\f1e0";
  font-family: 'FontAwesome';
  font-size: 17px;
  -webkit-font-smoothing: antialiased;
  left: 50%;
  margin-left: -8px;
  opacity: 0;
  position: absolute;
  transition: all linear .2s; }
.sharing-buttons li a .fa-left {
  transition: all linear .2s;
  margin-right: 6px; }
.sharing-buttons li a.facebook {
  background-color: #466ab3; }
.sharing-buttons li a.twitter {
  background-color: #3bbceb; }
.sharing-buttons li a.google-plus {
  background-color: #db6558; }
.sharing-buttons li a .social-name {
  transition: all linear .2s; }
.sharing-buttons li a:hover:before {
  opacity: 1; }
.sharing-buttons li a:hover .fa, .sharing-buttons li a:hover .social-name {
  opacity: 0; }

.hero {
  margin: 0px 0px 60px 0px; }
.hero h1, .hero p {
  margin: 0px;
  text-align: center; }
.hero h1 {
  color: #424242;
  font-size: 36px;
  font-weight: 500;
  margin: 0px 0px 20px 0px;
  text-transform: uppercase; }
.hero p {
  font-size: 18px;
  line-height: 30px; }

.position-relative{
  position:relative;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus {
  outline: 0 !important;
  outline-offset: 0  !important;
  background-image: none  !important;
  -webkit-box-shadow: none !important;
  box-shadow: none  !important;
}

.warning-box{
  background: red;
  font-size: 24px;
  font-weight: bold;
  padding: 20px;
  color: #fff;
  text-align:center;
}

.col-md-1,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-10,.col-md-11{
  .container{
    width: auto;
    padding:0;
  }
}