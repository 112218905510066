.faqs-box{
  h3{
    text-align:center;
    font-size:30px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .faq-box{
    padding:10px 0 10px;
    border-bottom:1px solid #DFDFDF;
    &:last-child{
      border-bottom:none;
    }
    h4{
      font-weight: 600;
      font-size: 16px;
    }
  }
}

.faqs-wrapper{
  h3{
    font-weight: 600;
    font-size: 18px;

    a{

      i{
        color: #DDDDDD;
      }
      &:hover, &:focus, &:active{
        color: @brand-red;
        text-decoration: none;
      }
    }
  }
}