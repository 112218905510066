.divider-box{
  padding: 30px 0 30px;
}

.divider-simple{
  padding: 30px 0 30px;
}

.divider-grey{
 background: #EDF1F2;
}

.divider-grey0{
  background: #EDF1F2;
  padding:0;
}

.divider-darkgrey{
  background: #6E7C85;
}

.divider-chart{
   background-image: url('../images/chart.jpg');
   background-position: center -50px;
   background-repeat: no-repeat;
   // background-size: cover;
 }

.divider-map{
  background-image: url('../images/map.jpg');
  background-position: center center;
  background-repeat: no-repeat;
   background-size: cover;
}

.divider-halfred{
  background-image: url('../images/halfred.png');
  background-position: center center;
  color: #fff;
  h1,h2,h3,h4,a{color: #fff}
  h3{
    font-size: 30px;
  }

  .col-md-6:nth-child(1){
    padding-right: 30px;

  }

  .col-md-6:nth-child(2){
    padding-left: 30px;
  }
}


@media (max-width: @screen-md){
  .divider-halfred{
    background-position: left center;

    .col-md-6:nth-child(1){
      p, h1, h2, h3 {
        text-align: left !important;
      }
    }

    .col-md-6:nth-child(2){
      padding-left: 15px;
    }

  }
}

.divider-footergrey{
  background-color: @site-footer-bg;
  color: #fff;
  h1,h2,h3,h4,a{color: #fff}
}


.divider-testimonials{
  padding: 20px 0;
  background-image: url('../images/blackfamily.jpg');
  background-position: 50% 50%;
  background-size: cover;
  margin:0;

  h1,h2,h3,p{
    color: #fff;
  }

}

.divider-black{
  padding: 20px 0;
  background: #1C1E1E;
  h1,h2,h3, a{
    color:#fff;
  }
}

.divider-firma{
  padding:0;
  .row,.col-sm-6{
    padding:0;
    margin:0;
  }
}

.firma-wrapper{
  padding: 20px;
  text-align:right;
  max-width: 600px;
  float:right;
  h1,h2,h3{
    text-align:right;
  }

  .firma-link{
    font-size:16px;
    display:inline-block;
    margin-left:30px;
  }
}


.container-title{
  text-align:center;
  position: relative;
  padding-bottom: 0.63em;
 margin-bottom: 20px;

}

.container-title.title-right{

}