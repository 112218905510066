.btn.btn-success{
  background: @brand-red;
  border:none;
  &:hover, &:active, &:focus{
    background: fade(@brand-red, 80%);
  }
}

.module-btn-color-red{
  background: @brand-red;
  color: #fff;
  font-size: 16px;
  display: block;
  padding: 10px 10px;
  text-align: center;
  margin: 5px 0;

  &:hover{
    background: fade(@brand-red, 95%);
    color: #fff;
    text-decoration: none;

  }
}

.module-btn{
  color: #fff;
  font-size: 16px;
  display: block;
  padding: 10px 10px;
  text-align: center;
  margin: 5px 0;

  &:hover{
    color: #fff;
    text-decoration: none;
  }
}

.module-btn-color-orange{
  background: orange;
  color: #fff;
  font-size: 16px;
  display: block;
  padding: 10px 10px;
  text-align: center;
  margin: 5px 0;

  &:hover{
    background: fade(orange, 95%);
    color: #fff;
    text-decoration: none;

  }
}

.module-btn-color-blue{
  background: #62a8ea;

  &:hover {
    background: fade(#62a8ea, 95%);
  }
}

.button-red{
  background: @brand-red;
  color: #fff;
  font-size:18px;
  font-weight: 600;
  padding:15px 15px;
  &:hover{
    background: fade(@brand-red, 90%);
    color: #fff;
    text-decoration: none;

  }
}