.price_column_0{
  float:left;
  width: 40%;
}

.price_column_1, .price_column_2,.price_column_3,.price_column_4{
  width:15%;
  float:left;
}

.price_hcaption{
  display:none;
}

.price_column{
  margin-bottom:30px;
}

.price_column ul{
  padding:0;
  margin:0;
  overflow: visible;
  list-style: none;
  //color: #111;
  li{
    list-style: none;
    text-align:center;
    width:100%;
    float:left;
    height: auto;
    overflow: visible !important;
    padding:7px 0 !important;
    line-height: normal !important;
    text-indent: 0 !important;

    box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;

    &:nth-child(even) {
     background:rgba(242,242,242,.5);
    }
  }
}

.price_column_0{
  ul li{
    text-align:left;
  }
}

.price_vertical_align_table{
  display: table;
  width: 100%;
  height: 100%;
  padding: 0 !important;
}

.price_vertical_align{
  display: table-cell;
  vertical-align: middle;
  padding: 0 !important;

  span{
    display: block;
    padding: 0 10px;
    font-size: 13px;

    &.price_hcaption{
      display:none;
    }
  }
}

.price_column_1{
  background: rgba(242,242,242, .5);
}

.price_header span{
  color: @brand-red;
  font-size: 24px !important;
}

.price_gold {
  background: #FAAB01;
  //background: #FFE599;
  //background: @brand-red;
  color: #fff;
  .price_vertical_align span{
    color: #fff !important;
  }
}

.price_footer span{
  font-size: 18px !important;
  font-weight: 500;
}

@media(max-width: @screen-sm){
  .price_column_0{
    display:none;
  }

  .price_column{
    width:50%;
  }
  .price_vertical_align span.price_hcaption{
    display:block;
  }
}

@media(max-width: @screen-xs){
  .price_column{
    width:100%;
  }
}