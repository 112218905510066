#google_translate_element{
 float:right;
}

//.language-switcher{
//  position:absolute;
//  top:5px;
//  right:0;
//
//  ul{
//    list-style-type: none;
//    li{
//      float: left;
//      a {
//        padding: 3px 5px;
//        color: #000;
//        margin-left: 5px;
//        text-indent:-9999px;
//      }
//    }
//  }
//}


/*!
 * Generated with CSS Flag Sprite generator (https://www.flag-sprites.com/)
 */

.flag {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url('../images/flags.png') no-repeat;
}


.flag.flag-de {
  background-position: 0 0;
}

.flag.flag-hu {
  background-position: 0 -24px;
}

.flag.flag-fr {
  background-position: -24px 0;
}

.flag.flag-gb {
  background-position: -48px 0;
}

.flag.flag-ro {
  background-position: -24px -24px;
}
