#mm-mobilenav {
//  background: @brand-color;
  .logo-mobile{
    max-width: 250px;
    text-align:center;
    margin: 0 auto;
    img{
      margin:0 auto;
    }
  }

  .mm-listview {

    .sub-menu {
      display: block;
      position: relative;
      background: none;
      width: auto;
    }

    li {
      float: none !important;

      a {

        width: 100%;
        float: none;
        text-align: center;
      }
    }
  }
}
#mobile-header{
  position: absolute;
  right: 20px;
  top: 20px;
  z-index:100;
}

.logo-mobile{
  img{
    max-height: 100px;
  }
}