.halfsplash-home{
  height: 550px;

  .homepage-splash-text{
    float:right;
    margin-top: 130px;
    text-align: center;
    h1{
      color: @brand-blue;
      font-size: 40px;
      font-weight: 600;
      margin-bottom: 0;
    }

    h2{
      margin-top:0;
      padding-top:0;
      color: @brand-red;
      font-size: 40px;
      font-weight: 800;
      span{
        font-size: 60px;
      }
    }
  }

  .btn{
    margin-top: 40px;
  }
}

.agentopinion{
  position:relative;

  .row{
    position:relative;

  }
  h2{
    text-align:center;
    margin:30px 0 80px 0;
  }

  h3{
    font-size: 30px;
  }

  .agentopinion-text{
    margin-top:80px;
    background: rgba(255,255,255,.9);
  }
}

.invitebanner{
  margin: 20px 0 0 0;
  padding: 40px 0;

  &.invitebanner-darkblue{
    background: #2A3140;
  }

  &.invitebanner-red{
    background: #C41628;
  }

  .invite-title{
    display:block;
    font-size:28px;
    color: #fff;
    margin: 10px 0;
    font-weight: 500;
  }

  p{
    font-size:15px;
    color: #fff;
  }
}

.btn-invite{
  display:block;
  color: #fff;
  padding: 15px;
  border:1px solid #fff;
  margin-top:15px;
}

.contact-wrapper{
  background: #fff;
  padding: 40px !important;

  h3{
    margin-top:0;
    font-size: 30px;
  }

  h5{
    font-size: 24px;
    font-weight: 500;
    font-family: @font-main;
  }
}

.footer{


  h3, h4{
    font-size:15px;
    margin-top: 5px;
    text-transform: uppercase;
  }

  .menu-module{
    margin-top:-5px;
    li {
      border-bottom: 1px solid #474D59;
      &:last-child{
        border:none;
      }
      a {

        display: block;
        padding: 5px 10px;
        color: #BABABA;
      }
    }
  }

  .contact-info{
    color: #BABABA;
    margin-top: 10px;

    .contact-box-item{
      padding-left:45px;
      display:block;
      position:relative;
      margin-bottom: 20px;

      .fa{
        position:absolute;
        left:15px;
        top:0;
        font-size: 25px;
        color: #fff;
      }
      
      span{
        display:block;
        text-transform: uppercase;
        color: #fff;
        font-weight: 500;
      }

    }
  }
}



@media (max-width: @screen-xs) {
  .halfsplash-home {
    height: 250px;
    min-height: 250px;
    .homepage-splash-text {
      float: none;
      margin-top: 30px;
      h1,h2{
        font-size:20px;
        span{
          font-size:20px;
        }
      }
    }
  }
}

@media (max-width: @screen-md){

}